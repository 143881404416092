<template>
    <div style="position:relative">
        <img class="borde" src="@/assets/borde.svg" alt="">

        <h2 class="h2-form">COMPLETÁ EL SIGUIENTE FORMULARIO PARA PARTICIPAR</h2>
        <div class="form-container">
            <el-row :gutter="20">

                <el-form :model="form" :rules="rules" ref="form" :label-position="'top'" :disabled="enviando"
                    class="demo-ruleForm">

                    <el-col :sm="8" :offset="0">
                        <el-form-item label="Nombre y Apellido" prop="nombre">
                            <el-input type="text" @input="validateForm" v-model="form.nombre"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8" :offset="0">
                        <el-form-item label="DNI" prop="dni">
                            <el-input @input="validateForm" v-model="form.dni"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8" :offset="0">
                        <!-- <el-form-item label="Fecha de Nacimiento" prop="dob">
                            <el-date-picker v-model="form.dob" style="width:100%" type="date"
                                placeholder="Seleccione fecha"></el-date-picker>
                        </el-form-item> -->
                        <el-form-item label="Fecha de Nacimiento" required>
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item prop="dobDay">
                                        <el-input @input="validateForm" v-model.number="form.dobDay" placeholder="Día"
                                            type="number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item prop="dobMonth">
                                        <el-input @input="validateForm" v-model.number="form.dobMonth" placeholder="Mes"
                                            type="number"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item prop="dobYear">
                                        <el-input @input="validateForm" v-model.number="form.dobYear" placeholder="Año"
                                            type="number"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8" :offset="0">
                        <el-form-item label="Celular" prop="celular">
                            <el-input @input="validateForm" v-model="form.celular"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8" :offset="0">
                        <el-form-item label="Mail" prop="email">
                            <el-input @input="validateForm" v-model="form.email"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="8" :offset="0">
                        <el-form-item label="¿Qué compraste?">
                            <el-select @change="validateForm" v-model="form.compra" placeholder="Seleccione"
                                style="width:100%">
                                <el-option label="Botella" value="botella"></el-option>
                                <el-option label="Lata" value="lata"></el-option>

                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24" :offset="0">
                        <br>
                        <el-form-item style="margin:15px 0px" prop="accepted">
                            <el-checkbox @change="validateForm" v-model="form.accepted">Acepto las <a
                                    href="/bases-y-condiciones">bases y condiciones</a> de la
                                promoción.</el-checkbox>
                        </el-form-item>
                        <br>
                        <el-form-item>
                            <el-button :type="boton" @click="onSubmit" :loading="enviando"
                                :disabled="!isFormValid">¡Participar!</el-button>
                        </el-form-item>

                    </el-col>

                    <el-col :span="12" :offset="0"></el-col>

                </el-form>
            </el-row>
        </div>
        <el-dialog title="" :show-close="false" :visible.sync="respuesta" :width="$isMobile() ? '320px' : '500px'">

            <img src="@/assets/perdedor.png" v-if="!$isMobile() && codigo == 'p'" style="width:100%; cursor:pointer"
                @click="cerrarVentana()" alt="">
            <img src="@/assets/perdedor-mobil.png" v-if="$isMobile() && codigo == 'p'"
                style="width:100%; cursor:pointer" @click="cerrarVentana()" alt="">
            <img src="@/assets/picada.png" v-if="!$isMobile() && codigo == 'gp'" style="width:100%; cursor:pointer"
                @click="cerrarVentana()" alt="">
            <img src="@/assets/picada-mobil.png" v-if="$isMobile() && codigo == 'gp'" style="width:100%; cursor:pointer"
                @click="cerrarVentana()" alt="">
            <img src="@/assets/ganador48.png" v-if="!$isMobile() && codigo == 'g48'" style="width:100%; cursor:pointer"
                @click="cerrarVentana()" alt="">
            <img src="@/assets/ganador48-mobil.png" v-if="$isMobile() && codigo == 'g48'"
                style="width:100%; cursor:pointer" @click="cerrarVentana()" alt="">
            <img src="@/assets/ganador229.png" v-if="!$isMobile() && codigo == 'g229'"
                style="width:100%; cursor:pointer" @click="cerrarVentana()" alt="">
            <img src="@/assets/ganador229-mobil.png" v-if="$isMobile() && codigo == 'g229'"
                style="width:100%; cursor:pointer" @click="cerrarVentana()" alt="">

        </el-dialog>

    </div>

</template>

<script>
import {HTTP} from "@/axios"
export default {
    name: 'FormComponent',
    data() {
        return {
            codigo:'',
            enviando:false,
            respuesta:false,
            form: {
                nombre: '',
                dni: '',
                dob: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                celular: '',
                email: '',
                compra: '',
                accepted: '',
                pdv_id: this.$route.params.pdv
            },
            boton: 'none',
            isFormValid: false,
            rules: {
                nombre: [
                    { required: true, message: 'Ingrese su nombre y apellido', trigger: 'blur' }
                ],
                dni: [
                    { required: true, message: 'Ingrese su DNI', trigger: 'blur' }
                ],
                dob: [
                    { type: 'date', required: true, message: 'Seleccione su fecha de nacimiento', trigger: 'change' }
                ],
                dobDay: [
                    { required: true, message: 'Ingrese el día de nacimiento', trigger: 'blur' },
                    { validator: this.validateDay, trigger: 'blur' }
                ],
                dobMonth: [
                    { required: true, message: 'Ingrese el mes de nacimiento', trigger: 'blur' },
                    { validator: this.validateMonth, trigger: 'blur' }
                ],
                dobYear: [
                    { required: true, message: 'Ingrese el año de nacimiento', trigger: 'blur' },
                    { validator: this.validateYear, trigger: 'blur' }
                ],
                celular: [
                    { required: true, message: 'Ingrese su celular', trigger: 'blur' }
                ],
                email: [
                    { type: 'email', required: true, message: 'Ingrese su email', trigger: 'blur' }
                ],
                purchase: [
                    { required: true, message: 'Seleccione su compra', trigger: 'change' }
                ],
                accepted: [
                    { validator: this.validateAccepted, trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        cerrarVentana()
        {
            this.form =  {
                    nombre: '',
                    dni: '',
                    dob: '',
                    dobDay: '',
                    dobMonth: '',
                    dobYear: '',
                    celular: '',
                    email: '',
                    purchase: '',
                    accepted: '',
                    pdv_id: this.$route.params.pdv
            },
          this.respuesta = false
        },
        getValid()
        {
          return 'default'
        },
        validateForm() {
         
            this.$refs.form.validate((valid) => {
                console.log(valid);
                this.isFormValid = valid;
                if (valid)
                {
                    this.boton = 'primary'
                }
            });
        },
        validateDay(rule, value, callback) {
            if (value < 1 || value > 31) {
                callback(new Error('El día debe estar entre 1 y 31'));
            } else {
                callback();
            }
        },
        validateMonth(rule, value, callback) {
            if (value < 1 || value > 12) {
                callback(new Error('El mes debe estar entre 1 y 12'));
            } else {
                callback();
            }
        },
        validateYear(rule, value, callback) {
            if (value < 1930 || value > 2006) {
                callback(new Error('El año debe estar entre 1930 y 2006'));
            } else {
                callback();
            }
        },
        validateAccepted(rule, value, callback) {
            if (!value) {
                callback(new Error('Debe aceptar las bases y condiciones'));
            } else {
                callback();
            }
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                
                if (valid) {
                    this.boton = 'primary'
                    this.enviando = true
                    HTTP.post('/participar', this.form)
                        .then(res => {
                            console.log(res.data);
                            if(res.data.codigo)
                                {
                                if (res.data.codigo === 'dni')
                                {
                                    this.$alert("El DNI ingresado ya se encuentra registrado, solo se permite un intento por persona", "INFORMACÍON", {type:'warning'})
                                    this.enviando = false
                                }
                                else{
                                    this.codigo = res.data.codigo
                                    setTimeout(() => {
                                        this.enviando = false

                                        this.respuesta = true
                                    }, 150);
                                }
                                 
                                }else{
                                this.enviando = false
                                this.$alert("No se pudo registrar su registro, aguarde unos minutos y reintente")
                                }
                           
                        })
                        .catch(() => {
                            this.enviando = false
                            this.$alert("No se pudo registrar su registro, aguarde unos minutos y reintente")
                        })
                } else {
                    console.log('Error al enviar el formulario');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
.h2-form{
    padding:50px;
    color: white;
    font-weight: normal;
    margin:0;
    padding-top: 60px;
    padding-bottom: 30px;
}
.borde {
    position: absolute;
    top: -1px;
    left: 5px;
    width: 90%;
    z-index: 0;

}
.form-container {
   
    margin: 0 auto;
    padding: 0px;
   
}

.demo-ruleForm {
    
    padding: 50px;
    border-radius: 5px;
    padding-top: 20px;
}

a {
    color: #fff;
    
}

a:hover {
    text-decoration: underline;
}
</style>
