<template>
    <header class="header">

        <el-row :gutter="20">
            <el-col :sm="4" :xs="12" :offset="0">
                <img src="@/assets/logo-salta-s.svg" @click="$router.push({name: 'home'})"
                    style="height:45px; cursor:pointer; float:left; padding-left:50px" alt="">
            </el-col>
            <el-col :sm="20" :xs="12" :offset="0">
                <a href="#" v-if="$route.name === 'bases'" class="volver"
                    @click.prevent="$router.push({ name: 'home' })"> <span class="el-icon-arrow-left"></span>
                    Volver al Concurso</a>
            </el-col>
        </el-row>


    </header>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style scoped>
.header {
    background-color: var(--azul-oscuro);
    /* Color de fondo del header */
    text-align: center;
    padding: 20px 24px;
    padding-left: 0px;
        float: left;
}
.volver {
  
    font-size: 26px;
    color: #03a9f4;
    text-decoration: none;
    float: right;
    font-weight: normal;
}

@media screen and (max-width:480px) {
    .header {
            background-color: var(--azul-oscuro);
            /* Color de fondo del header */
            float: left;
            padding: 20px 24px;
                padding-left: 0px;
                float: left;
            
        }
                .volver {
        
                    font-size: 22px;
                    color: #03a9f4;
                    text-decoration: none;
                    float: right;
                    font-weight: normal;
                }
}

</style>
