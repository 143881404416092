import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueMobileDetection from "vue-mobile-detection";
Vue.use(VueMobileDetection);
import VueMoment from 'vue-moment'
const moment = require('moment')
require('moment/locale/es')
Vue.config.productionTip = false
Vue.use(VueMoment, {
  moment
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
