<template>
    <footer class="footer">
        <div class="footer-content">
          
            <p class="legal-text">PROMOCIÓN VÁLIDA PARA MAYORES DE 18 AÑOS. BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A
                MENORES DE 18 AÑOS. 
                <br>
                 <a href="/bases-y-condiciones" >Leer Bases y Condiciones de la Promoción.</a></p>
        </div> 
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.footer {
    background-color: #1e4274;
    /* Color de fondo del footer */
    text-align: center;
    padding: 10px;
}

.footer-content p {
    color: #ffffff;
    /* Color del texto */
    font-size: 1.2em;
}

.legal-text {
    font-size: 0.9em;
    color: #cccccc;
    /* Color del texto legal */
}

.legal-text a {
    color: #ffffff;
    /* Color del enlace */
}
</style>
