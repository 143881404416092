<template>
  <div>
    <div id="home" class="canvas-container">
      <canvas id="homec"></canvas>
      <img class="entradas" v-if="!this.$isMobile()" src="@/assets/entradas.png" alt="">
      <img class="entradas" v-if="this.$isMobile()" src="@/assets/entrada-mobile.png" alt="">
      <div class="textos">
        <img class="alenta" src="@/assets/alenta.svg" alt="">
        <img class="junto" src="@/assets/junto.svg" alt="">
        <img class="texto" src="@/assets/texto.svg" alt="">
        <img class="desde" src="@/assets/desde.svg" alt="">
        <img class="hasta" src="@/assets/hasta.svg" alt="">
      </div>
    </div>
    <Formulario />
  </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'
import Formulario from "@/components/Formulario.vue"

Vue.use(VueConfetti)

export default {
  name: 'ConfettiCanvas',
  components: { Formulario },
  data() {
    return {
      // data properties
    }
  },
  mounted() {
    this.setCanvasSize();
    this.startConfetti();
    window.addEventListener('resize', this.setCanvasSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setCanvasSize);
  },
  methods: {
    setCanvasSize() {
      const canvas = document.getElementById('homec');
      if (canvas) {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }
    },
    startConfetti() {
      this.$confetti.start({
        particles: [
          {
            type: 'rect',
            size: 10,
            dropRate: 10
          },
        ],
        canvasId: 'homec',
        defaultSize: 30,
        particlesPerFrame: 1,
        defaultColors: [
          '#cac7c8'
        ],
      });
    }
  }
}
</script>

<style scoped>
.entradas {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.textos {
  padding: 20px;
}

.alenta {
  position: absolute;
  top: 50px;
  left: 50px;
  width: 450px;
  z-index: 0;
}

.junto {
  position: absolute;
  top: 150px;
  left: 50px;
  width: 380px;
  z-index: 0;
}

.texto {
  position: absolute;
  top: 250px;
  left: 50px;
  width: 400px;
  z-index: 0;
}

.desde {
  position: absolute;
  top: 430px;
  left: 50px;
  width: 100px;
  z-index: 0;
}

.hasta {
  position: absolute;
  top: 430px;
  left: 260px;
  width: 100px;
  z-index: 0;
}

.borde {
  position: absolute;
  top: 99.1%;
  left: 5px;
  width: 90%;
  z-index: 0;
}

.canvas-container {
  background: linear-gradient(0deg, rgba(29, 54, 85, 1) 24%, rgba(47, 94, 144, 1) 57%, rgba(25, 66, 117, 1) 100%);
  background-size: contain;
  background-position: top center;
  width: 100%;
  height: 500px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

#homec {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-image: url(~@/assets/fondo.png);
}

/* Estilos para tablet */
@media (max-width: 768px) {

  .alenta,
  .junto,
  .texto,
  .desde,
  .hasta {
    left: 20px;
    width: 80%;
  }

  .alenta {
    top: 30px;
    width: 70%;
  }

  .junto {
    top: 100px;
    width: 70%;
  }

  .texto {
    top: 200px;
    width: 70%;
  }

  .desde,
  .hasta {
    top: 300px;
    width: 70px;
  }
}

/* Estilos para mobile */
@media (max-width: 480px) {
.canvas-container {
   
    height: 590px;
   
  }
  .alenta,
  .junto,
  .texto,
  .desde,
  .hasta {
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .alenta {
    top: 20px;
    width: 80%;
  }

  .junto {
    top: 90px;
    width: 80%;
  }

  .texto {
    top: 150px;
    width: 80%;
  }

  .desde {
    top: 280px;
    left:90px;
    width: 120px;
  }

  .hasta {
    top: 280px;
    left: 80%;
    transform: translateX(-50%);
    width: 120px;
  }

  .entradas {
    top: 320px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
}
</style>
